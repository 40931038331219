export type RouteKeys = 'wms-job' | 'task' | 'fulfillment' | 'sales-order' | 'outbound-shipment' | 'cycle-count' | 'work-order' | 'purchase-order' | 'asn';

export function getRouteMap(id: number) {
  return new Map<RouteKeys, (string | number)[]>([
    ['wms-job', ['/operations/jobs/detail', id]],
    ['task', ['/operations/tasks/detail', id]],
    ['fulfillment', ['/fulfillment/fulfillment-requests/detail', id]],
    ['sales-order', ['/outbound/sales-orders/detail', id]],
  ])
}

// getRouteMap(2).get('job')